import { render, staticRenderFns } from "./EditSelectedDigiBoards.vue?vue&type=template&id=373d8528&scoped=true"
import script from "./EditSelectedDigiBoards.vue?vue&type=script&lang=js"
export * from "./EditSelectedDigiBoards.vue?vue&type=script&lang=js"
import style0 from "./EditSelectedDigiBoards.vue?vue&type=style&index=0&id=373d8528&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "373d8528",
  null
  
)

export default component.exports